.fb-container {
    position: relative;
    overflow: hidden;
    padding: 4px;
    padding-bottom: 56.25%;
}

    .fb-container iframe,
    .fb-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

/* And set the max-width of the parent element */
.fb-wrap {
    width: 100%;
    max-width: 350px;
}

.ig-container {
    position: relative;
    overflow: hidden;
    height: 0;
}

    .ig-container iframe,
    .ig-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

/* And set the max-width of the parent element */
.ig-wrap {
    width: 100%;
    max-width: 350px;
}

.yt-container {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-left: 4px;
    padding-bottom: 56.25%;
}
    .yt-container iframe,
    .yt-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
    }
.yt-wrap {
    width: 100%;
    max-width: 300px;
}
